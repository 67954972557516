const prefix = '@alerts';

export const AlertActionType = {
  GetAlerts: `${prefix}/GET_ALERTS`,
  CreateAlert: `${prefix}/CREATE_ALERT`,
  DeleteAlert: `${prefix}/DELETE_ALERT`,
  GetAlertsFeed: `${prefix}/GET_ALERTS_FEED`,
  GetAlertsSuggestions: `${prefix}/GET_ALERTS_SUGGESTIONS`,
  GetAlertsCount: `${prefix}/GET_ALERTS_COUNT`,
  UpdateLastCheckedAlerts: `${prefix}/UPDATE_LAST_CHECKED_ALERTS`,
  GetInitialAlertsSuggestions: `${prefix}/GET_INITIAL_ALERTS_SUGGESTIONS`,
  GetAlertsSuggestionsByText: `${prefix}/GET_ALERTS_SUGGESTIONS_BY_TEXT`,
};
