export const GET_ALERTS_SUGGESTIONS_QUERY = `
query GetAlertsSuggestionsQuery($query: String!) {
  me {
    searchAlertsSuggestions(query: $query) {
      term
      type
      manyNotifications
      category {
        id
        title
        slug
      }
    }
  }
}
`;
