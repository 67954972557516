import { Alert, AlertSuggestion } from '../../../domain/entities/Alert';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { AlertActionType } from './actionTypes';
import { ALERTS_COUNT_QUERY } from './queries/getAlertsCount';
import { UPDATE_LAST_CHECKED_ALERTS_MUTATION } from './mutations/updateLastCheckedAlerts';
import { GET_ALERTS_SUGGESTIONS_QUERY } from './queries/getAlertsSuggestions';
import { GET_INITIAL_ALERTS_SUGGESTIONS_QUERY } from './queries/getInitialAlertsSuggestions';
import {
  GetAlertsCountCache,
  GetAlertsCountResponse,
} from './types/GetAlertsCount';
import {
  GetAlertsSuggestionsCache,
  GetAlertsSuggestionsResponse,
  GetAlertsSuggestionsVariables,
} from './types/GetAlertsSuggestions';
import {
  GetInitialAlertsSuggestionsCache,
  GetInitialAlertsSuggestionsResponse,
} from './types/GetInitialAlertsSuggestions';
import { UpdateLastCheckedAlertsResponse } from './types/UpdateLastCheckedAlerts';
import { clearAlertsCountCache } from './helpers/getAlertsCount';
import {
  GetAlertsCache,
  GetAlertsResponse,
  GetAlertsVariables,
} from './types/GetAlerts';
import { GET_ALERTS_QUERY } from './queries/getAlerts';
import { DeleteAlertResponse, DeleteAlertVariables } from './types/DeleteAlert';
import { DELETE_ALERT_MUTATION } from './mutations/deleteAlert';
import { CreateAlertResponse, CreateAlertVariables } from './types/CreateAlert';
import { CREATE_ALERT_MUTATION } from './mutations/createAlert';
import { AuthGuard } from '../core/guards/authGuard';
import { Interceptor } from '../core/interceptors';
import { ResponseResult } from '../core/request';
import { UpdateAlertResponse, UpdateAlertVariables } from './types/UpdateAlert';
import { UPDATE_ALERT_MUTATION } from './mutations/updateAlert';
import {
  GetAlertsFeedCache,
  GetAlertsFeedResponse,
  GetAlertsFeedVariables,
} from './types/GetAlertsFeed';
import { userOfferVotes } from '../votes/endpoints';
import { ALERTS_FEED_QUERY } from './queries/getAlertsFeed';
import { GET_ALERTS_SUGGESTIONS_BY_TEXT_QUERY } from './queries/getAlertsSuggestionByText';
import {
  GetAlertsSuggestionsByTextCache,
  GetAlertsSuggestionsByTextResponse,
  GetAlertsSuggestionsByTextVariables,
} from './types/GetAlertsSuggestionsByText';

const updateLastCheckedAlertsInterceptor = () => {
  clearAlertsCountCache();
};

export const getAlertsSuggestions = createNewEndpoint<
  GetAlertsSuggestionsResponse,
  GetAlertsSuggestionsVariables,
  AlertSuggestion[],
  GetAlertsSuggestionsCache
>({
  id: AlertActionType.GetAlertsSuggestions,
  query: GET_ALERTS_SUGGESTIONS_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.me.searchAlertsSuggestions,
  cacheTransform: ({ data, loading }, current) => {
    const suggestions = (loading ? current?.suggestions : data) || [];
    return {
      suggestions,
    };
  },
});

export const getAlertsCount = createNewEndpoint<
  GetAlertsCountResponse,
  undefined | void,
  GetAlertsCountResponse['me']['newNotificationsCount'],
  GetAlertsCountCache
>({
  id: AlertActionType.GetAlertsCount,
  query: ALERTS_COUNT_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (result) => result.me.newNotificationsCount,
  cacheTransform: ({ data }, current) => ({
    count: data ?? current?.count,
  }),
});

export const updateLastCheckedAlerts = createNewEndpoint<
  UpdateLastCheckedAlertsResponse,
  void,
  boolean,
  undefined | void
>({
  id: AlertActionType.UpdateLastCheckedAlerts,
  query: UPDATE_LAST_CHECKED_ALERTS_MUTATION,
  method: GraphQlMethods.Post,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.UpdateLastCheckedAlertsResponse,
  interceptors: [updateLastCheckedAlertsInterceptor],
});

export const getInitialAlertsSuggestions = createNewEndpoint<
  GetInitialAlertsSuggestionsResponse,
  void,
  GetInitialAlertsSuggestionsResponse['me']['initialAlertKeywordSuggestions'],
  GetInitialAlertsSuggestionsCache
>({
  id: AlertActionType.GetInitialAlertsSuggestions,
  query: GET_INITIAL_ALERTS_SUGGESTIONS_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.initialAlertKeywordSuggestions,
  cacheTransform: ({ data }) => ({
    suggestions: data || [],
  }),
});

export const getAlerts = createNewEndpoint<
  GetAlertsResponse,
  GetAlertsVariables,
  Alert[],
  GetAlertsCache
>({
  id: AlertActionType.GetAlerts,
  query: GET_ALERTS_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.alerts,
  cacheTransform: ({ loading, data }) => ({
    loading,
    alerts:
      data?.map((alert) => ({
        ...alert,
        rawKeyword: alert.rawKeyword.toLocaleLowerCase(),
      })) || [],
  }),
});

const deleteAlertInterceptor: Interceptor<
  ResponseResult<boolean, DeleteAlertVariables>,
  unknown
> = ({ incoming: { data, variables, loading } }) => {
  if (!data || loading) return;
  const alerts = getAlerts.cache$.value?.alerts || [];
  const filtered =
    alerts.filter((alert) => alert.id !== variables?.alertId) || [];

  getAlerts.cache$.next({ alerts: filtered, loading: false });
};

export const deleteAlert = createNewEndpoint<
  DeleteAlertResponse,
  DeleteAlertVariables,
  boolean
>({
  id: AlertActionType.DeleteAlert,
  query: DELETE_ALERT_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response) => response.me.deleteAlert,
  interceptors: [deleteAlertInterceptor],
});

export const createAlert = createNewEndpoint<
  CreateAlertResponse,
  CreateAlertVariables,
  Alert
>({
  id: AlertActionType.CreateAlert,
  query: CREATE_ALERT_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response) => response.me.createAlert,
});

const updateAlertInterceptor: Interceptor<
  ResponseResult<Alert, UpdateAlertVariables>,
  unknown
> = ({ incoming: { data, loading } }) => {
  if (!data || loading) return;
  const alerts = [...(getAlerts.cache$.value?.alerts || [])].map((alert) =>
    alert.id === data.id ? { ...data, category: alert.category } : alert
  );
  getAlerts.cache$.next({ alerts, loading: false });
};

export const updateAlert = createNewEndpoint<
  UpdateAlertResponse,
  UpdateAlertVariables,
  Alert
>({
  id: AlertActionType.CreateAlert,
  query: UPDATE_ALERT_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  interceptors: [updateAlertInterceptor],
  transform: (response) => response.me.updateAlert,
});

const getAlertsFeedInterceptor: Interceptor<
  ResponseResult<GetAlertsFeedResponse['me']['alertOffers'], unknown>,
  unknown
> = ({ incoming: { data, loading } }) => {
  if (!data || loading) return;
  const offerIds = data.edges.map((alert) => alert.offer.id);
  userOfferVotes.requestAsPromise({ offerIds });
};

export const getAlertsFeed = createNewEndpoint<
  GetAlertsFeedResponse,
  GetAlertsFeedVariables,
  GetAlertsFeedResponse['me']['alertOffers'],
  GetAlertsFeedCache
>({
  id: AlertActionType.GetAlertsFeed,
  query: ALERTS_FEED_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  interceptors: [getAlertsFeedInterceptor],
  transform: (response) => response.me.alertOffers,
  cacheTransform: ({ loading, data, variables }, current) => {
    const isPagination = !!variables?.after;
    const paginationLoading = loading && isPagination;
    const incomingAlerts =
      data?.edges.map(({ keyword, offer }) => ({
        ...offer,
        keyword,
      })) || [];

    const edges = isPagination
      ? [...(current?.edges || []), ...incomingAlerts]
      : incomingAlerts;

    const pageInfo = data?.pageInfo;

    return {
      edges,
      loading: loading && !isPagination,
      pageInfo,
      paginationLoading,
    };
  },
});

export const getAlertsSuggestionByText = createNewEndpoint<
  GetAlertsSuggestionsByTextResponse,
  GetAlertsSuggestionsByTextVariables,
  GetAlertsSuggestionsByTextResponse['public']['getAlertsSuggestionsByText'],
  GetAlertsSuggestionsByTextCache
>({
  id: AlertActionType.GetAlertsSuggestionsByText,
  query: GET_ALERTS_SUGGESTIONS_BY_TEXT_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.getAlertsSuggestionsByText,
  cacheTransform: ({ data, loading }) => ({
    suggestions: data,
    loading,
  }),
});
