import Cookies from 'js-cookie';
import { EndpointGuardMessage } from '.';

export const heldRequests = new Map<string, () => void>();

/**
 * A Guard to prevent a request to be called by an unauthenticated user.
 * If the request is rejected it will be held and be executed later.
 * (client-side only)
 *
 * @param message Guard message from endpoint (EndpointGuardMessage)
 * @param mustHeldRequest Flag to decide if a rejected request should be
 * held to retry after login (default is `true`)
 * @returns If the request can continue
 */
export function PreventUnauthenticatedGuard<Response, Variables>(
  message: EndpointGuardMessage<Response, Variables>,
  mustHeldRequest = true
) {
  const userAuthenticated = Cookies.get('probably_logged') === 'true';

  if (message.loading && !userAuthenticated) {
    if (mustHeldRequest) heldRequests.set(message.id, message.retry);
    return true;
  }

  return false;
}

export function callHeldRequests() {
  heldRequests.forEach((retry) => retry());
  heldRequests.clear();
}
