export const UPDATE_ALERT_MUTATION = `
  mutation UpdateAlert($id: ID!, $type: AlertType!) {
    me {
      updateAlert(id: $id, type: $type) {
        id
        rawKeyword
        type
        createdAt
        manyNotifications
        category {
          id
          title
          slug
        }
      }
    }
}
`;
