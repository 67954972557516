import { useModal } from '@pelando/components';
import { useCallback } from 'react';
import { Flag } from '@pelando/fontawesome/icons';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { FlagIcon, ModalButton, ModalHeader, Title, modalStyle } from './style';
import { useTranslation } from '../../../../../hooks/useTranslation';

type RemoveAlertModalProps = {
  alertToBeDeleted: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export function RemoveAlertModal({
  alertToBeDeleted,
  onCancel,
  onConfirm,
}: RemoveAlertModalProps) {
  const { t } = useTranslation('alerts');

  return (
    <div>
      <ModalHeader>
        <FlagIcon icon={Flag} />
        <Title>{t('remove-alert-modal-title', alertToBeDeleted)}</Title>
      </ModalHeader>
      <ModalButton responsive light onClick={onConfirm}>
        {t('remove-alert-modal-confirm-button')}
      </ModalButton>
      <ModalButton responsive onClick={onCancel}>
        {t('remove-alert-modal-cancel-button')}
      </ModalButton>
      <LockScrollGlobally lock />
    </div>
  );
}

type UseRemoveAlertModalParams = { onConfirm: (id: string) => void };

export function useRemoveAlertModal({ onConfirm }: UseRemoveAlertModalParams) {
  const { closeModal, showModal } = useModal();

  const handleConfirm = useCallback(
    (id: string) => {
      onConfirm(id);
      closeModal();
    },
    [closeModal, onConfirm]
  );

  const showRemoveAlertModal = useCallback(
    (id: string, term: string) => {
      showModal(
        <RemoveAlertModal
          alertToBeDeleted={term}
          onCancel={closeModal}
          onConfirm={() => handleConfirm(id)}
        />,
        { style: modalStyle }
      );
    },
    [closeModal, handleConfirm, showModal]
  );

  return showRemoveAlertModal;
}
