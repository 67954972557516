export const CREATE_ALERT_MUTATION = `
  mutation CreateAlert($alert: alertInput!) {
    me {
      createAlert(alert: $alert) {
        id
        rawKeyword
        createdAt
        type
        manyNotifications
        category {
          id
          title
          slug
        }
      }
    }
  }
`;
