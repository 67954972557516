import {
  BodyHeavy,
  BodyLight,
  Button,
  CaptionHeavy,
  CaptionLight,
  Colors,
  H2Heavy,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled, { css } from 'styled-components';

export const ModalIconTitle = styled(Icon)`
  font-size: 48px;
  color: rgb(${Theme.colors.Brand});
  margin-bottom: 16px;
`;

export const Title = styled.h3`
  ${H2Heavy};
  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  ${BodyLight}
  color: rgb(${Colors.Gray});
  margin-bottom: 24px;

  mark {
    ${BodyHeavy}
    background-color: unset;
    color: rgb(${Colors.Gray});
  }
`;

export const ModalHeader = styled.div`
  text-align: center;
`;

export const Listbox = styled.ul`
  list-style-type: none;
  width: 100vw;
  margin-left: -24px;
  margin-bottom: 24px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: auto;
    margin: 0 16px 24px 16px;
  }
`;

export const Option = styled.li`
  &[aria-selected='true'],
  &:hover,
  &:focus-within {
    background-color: rgb(${Theme.colors.Kilo});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    border-radius: 12px;
  }
`;

export const OptionButton = styled.button`
  ${CaptionLight}
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 12px 24px;
  width: 100%;
  text-align: left;
  color: rgb(${Theme.colors.Alpha});
  background-color: unset;

  mark {
    ${CaptionHeavy}
    background-color: unset;
    color: rgb(${Theme.colors.Alpha});
  }
`;

export const CreateButton = styled(Button)`
  height: 48px;
`;

export const CheckIcon = styled(Icon)`
  color: rgb(${Colors.Green});
  margin-left: auto;
  font-size: 24px;
`;

export const OptionIcon = styled(Icon)`
  font-size: 24px;
  margin-right: 4px;
`;

export const Container = styled.div`
  margin-top: -8px;
`;

export const modalStyle = css`
  @media ${MediaQuery.SCREEN_MD_UP} {
    max-width: 391px;
  }
`;
