import { Button, H2Heavy, Icon, MediaQuery, Theme } from '@pelando/components';
import styled, { css } from 'styled-components';

export const FlagIcon = styled(Icon)`
  font-size: 48px;
  margin-bottom: 16px;
  color: rgb(${Theme.colors.Brand});
`;

export const Title = styled.h3`
  ${H2Heavy}
`;

export const ModalHeader = styled.div`
  margin-bottom: 52px;
  margin-top: -8px;
  text-align: center;
`;

export const ModalButton = styled(Button)`
  height: 48px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const modalStyle = css`
  @media ${MediaQuery.SCREEN_MD_UP} {
    max-width: 391px;
  }
`;
