export const GET_ALERTS_SUGGESTIONS_BY_TEXT_QUERY = `
query GetAlertsSuggestionsByText($query: String!, $limit: Int) {
  public {
		getAlertsSuggestionsByText(query: $query, limit: $limit){
      term
      type
      manyNotifications
      category {
        id
        title
        slug
      }
    }
  }
}
`;
