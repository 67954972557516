import { OFFER_FRAGMENT } from '../../fragments/offer';

const REMOVE_VOTE_MUTATION = `
  mutation RemoveVoteMutation($id: ID!) {
    me {
      removeVote(id: $id) {
        ...OfferFragment
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export default REMOVE_VOTE_MUTATION;
