import { BannerType, HEADER_HEIGHT, useToast } from '@pelando/components';
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { AlertType } from '../../../domain/entities/Alert';
import { AlertsComponentNames } from '../../../domain/events/analytics';
import { useApiCache } from '../useApiCache';
import {
  getAlerts,
  deleteAlert,
  updateAlert,
} from '../../../infra/api/alerts/endpoints';
import { useAlertTypePicker } from '../../pages/secure/alerts/components/AlertTypePicker';
import { UpdateAlertVariables } from '../../../infra/api/alerts/types/UpdateAlert';
import { useRemoveAlertModal } from '../../pages/secure/alerts/components/RemoveAlertModal';
import { CreateAlertCallback } from '../../pages/secure/alerts/types';
import { createAndAddAlert } from '../../../infra/api/alerts/helpers/createAlert';
import { Category } from '../../../domain/entities/Category';
import { useTranslation } from '../useTranslation';

export function useEnhancedAlerts() {
  const { showToast } = useToast();
  const { t } = useTranslation('alerts');
  const { query } = useRouter();

  const origin = query?.origin as string;

  const { alerts, loading: isLoading } = useApiCache(getAlerts.cache$) || {};

  const deleteWithFeedback = useCallback(
    (alertId: string) => {
      deleteAlert
        .requestAsPromise({ alertId })
        .then(({ data }) => {
          if (!data) return;
          showToast({
            titleText: t('delete-alert-success-toast'),
            topPosition: HEADER_HEIGHT,
            text: t('alert-toast-sucess-text'),
            bannerType: BannerType.DEFAULT,
          });
        })
        .catch(() => {
          showToast({
            titleText: t('alert-title-error-toast'),
            topPosition: HEADER_HEIGHT,
            text: t('alert-text-error-toast'),
            bannerType: BannerType.ERROR,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToast]
  );

  const showRemoveAlertModal = useRemoveAlertModal({
    onConfirm: deleteWithFeedback,
  });

  const deleteHandler = useCallback(showRemoveAlertModal, [
    showRemoveAlertModal,
  ]);

  const createWithFeedback = useCallback(
    (
      keyword: string,
      type: AlertType,
      category?: Category,
      componentName?: AlertsComponentNames,
      haveToshowToast: boolean = true,
      origin?: string
    ) => {
      const rawKeyword = keyword
        .toLocaleLowerCase()
        .replace(/[ ]{2,}/gm, ' ')
        .trim();

      createAndAddAlert({
        rawKeyword,
        type,
        category,
        componentSourceName: componentName,
        origin,
      })
        .then(({ data }) => {
          if (!data) return;
          if (haveToshowToast) {
            showToast({
              titleText: t('create-alert-success-toast'),
              topPosition: HEADER_HEIGHT,
              text: `${t('alert-toast-sucess-text')} 😍`,
              bannerType: BannerType.SUCCESS,
            });
          }
        })
        .catch(() => {
          showToast({
            titleText: t('alert-title-error-toast'),
            topPosition: HEADER_HEIGHT,
            text: t('alert-text-error-toast'),
            bannerType: BannerType.ERROR,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToast]
  );

  const showCreateAlertModal = useAlertTypePicker({
    onConfirm: createWithFeedback,
  });

  const createHandler: CreateAlertCallback = useCallback(
    ({ suggestion, componentName, haveToshowToast = true }) => {
      const { type, term, manyNotifications, category } = suggestion;

      if (manyNotifications) {
        showCreateAlertModal(type, term, category, componentName);
        return;
      }

      createWithFeedback(
        term,
        type,
        category,
        componentName,
        haveToshowToast,
        origin
      );
    },
    [showCreateAlertModal, createWithFeedback, origin]
  );

  const updateHandler = useCallback(
    (params: UpdateAlertVariables) => {
      updateAlert
        .requestAsPromise(params)
        .then(({ data }) => {
          if (!data) return;
          showToast({
            titleText: t('update-alert-success-toast'),
            topPosition: HEADER_HEIGHT,
            text: `${t('alert-toast-sucess-text')} 😍`,
            bannerType: BannerType.SUCCESS,
          });
        })
        .catch(() => {
          showToast({
            titleText: t('alert-title-error-toast'),
            topPosition: HEADER_HEIGHT,
            text: t('alert-text-error-toast'),
            bannerType: BannerType.ERROR,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToast]
  );

  useEffect(() => {
    getAlerts.requestAsPromise();
  }, []);

  return {
    alerts: alerts || [],
    isLoading,
    createHandler,
    deleteHandler,
    updateHandler,
  };
}
