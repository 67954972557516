import { Vote, VoteKind } from '../../../domain/entities/Vote';
import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { AuthGuard } from '../core/guards/authGuard';
import { PreventUnauthenticatedGuard } from '../core/guards/preventUnauthenticated';
import { VoteActionType } from './actionTypes';
import REMOVE_VOTE_MUTATION from './mutations/removeVote';
import VOTE_MUTATION from './mutations/vote';
import USER_OFFER_VOTES_QUERY from './queries/userOfferVotes';
import { RemoveVoteResponse, RemoveVoteVariables } from './types/RemoveVote';
import {
  UserOfferVotesCache,
  UserOfferVotesResponse,
  UserOfferVotesVariables,
  VoteWithTemperature,
} from './types/UserOfferVotes';
import { VoteResponse, VoteVariables } from './types/Vote';

export const userOfferVotes = createNewEndpoint<
  UserOfferVotesResponse,
  UserOfferVotesVariables,
  Vote[],
  UserOfferVotesCache
>({
  id: VoteActionType.GetVoteInfo,
  query: USER_OFFER_VOTES_QUERY,
  method: GraphQlMethods.Get,
  guards: [PreventUnauthenticatedGuard],
  transform: (response) => response.me.offerVotes,
  cacheTransform: ({ data }, current) => {
    if (!data) return current || {};
    const nextData = data?.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr }),
      {}
    );
    return { ...current, ...nextData };
  },
});

export const vote = createNewEndpoint<
  VoteResponse,
  VoteVariables,
  VoteWithTemperature
>({
  id: VoteActionType.Vote,
  query: VOTE_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response, variables) => ({
    id: variables.id,
    kind: variables.voteKind,
    voted: true,
    temperature: response.me.vote.temperature,
  }),
  cacheTransform: ({ data }) => {
    if (!data) return undefined;
    const votesCache = userOfferVotes.cache$.value || {};

    userOfferVotes.cache$.next({
      ...votesCache,
      [data.id]: data,
    });

    return undefined;
  },
});

export const removeVote = createNewEndpoint<
  RemoveVoteResponse,
  RemoveVoteVariables,
  VoteWithTemperature
>({
  id: VoteActionType.RemoveVote,
  query: REMOVE_VOTE_MUTATION,
  method: GraphQlMethods.Post,
  guards: [AuthGuard],
  transform: (response) => ({
    id: response.me.removeVote.id,
    kind: VoteKind.INCREASE,
    voted: false,
    temperature: response.me.removeVote.temperature,
  }),
  cacheTransform: ({ data }) => {
    if (!data) return undefined;
    const votesCache = userOfferVotes.cache$.value || {};

    userOfferVotes.cache$.next({
      ...votesCache,
      [data.id]: data,
    });

    return undefined;
  },
});
