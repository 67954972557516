import { FAQ } from './FAQ';
import { Image } from './Image';
import { ProductExternalDeal, ProductCommunityDeal, DealStore } from './Offer';
import { MinPriceStore } from './Store';

export type ProductPriceHistoryPoint = {
  date: string;
  price: string;
};

export const enum PriceStatus {
  HOT = 'HOT',
  COLD = 'COLD',
  NORMAL = 'NORMAL',
  UNKNOWN = 'UNKNOWN',
}

export const enum IndexSource {
  Omnilogic = 'OMNILOGIC',
  SingleLogic = 'SINGLELOGIC',
}

export const enum SpecType {
  Basic = 'BASIC',
  Common = 'COMMON',
}

export type ProductSpec = {
  name: string;
  value: string;
  type: SpecType;
};

export type ProductSpecSearchTerm = {
  name: string;
  term: string;
};

type RangePercentageMessage = {
  title: string;
  subTitle: string;
  description: string;
  status: PriceStatus;
};

type AutoGeneratedDescription = {
  description: string;
  specsDescription: string;
};

export type Product = {
  id: string;
  title: string;
  image: Image;
  canonicalUrl?: string;
  description?: string;
  categoryId?: string;
  categoryName?: string;
  enableIndexing: boolean;
  currentMinPrice: number;
  currentMaxPrice: number;
  rangePercentage30Days?: number;
  minPrice30Days?: number;
  maxPrice30Days?: number;
  rangePercentage90Days?: number;
  minPrice90Days?: number;
  maxPrice90Days?: number;
  minPriceStore?: MinPriceStore;
  rangePercentageMessage: RangePercentageMessage;
  nominalModel?: string;
  specs: ProductSpec[];
  specsSearchTerms?: ProductSpecSearchTerm[];
  indexSource?: IndexSource;
  totalStoresCount?: number;
  offerCount: number;
  faq?: FAQ;
  autoGeneratedDescription?: AutoGeneratedDescription;
};

export type PostEditOfferResponse = {
  data?: { id: string };
  error?: Error;
};

export type AvailableColor = {
  color: string;
  image: Image;
};

export type RelatedProduct = {
  id: string;
  title: string;
  storageCapacity: string;
  availableColors: AvailableColor[];
};

export type ProductDeal = ProductCommunityDeal | ProductExternalDeal;

export type NormalizedProductDeal = {
  id: string;
  title: string;
  url: string;
  price: number;
  image?: Image;
  store?: DealStore;
  couponCode?: string;
  isProductCommunityDeal: boolean;
  ean?: string;
};

export enum ProductCategory {
  Smartphone = 'Smartphone',
  Videogame = 'Videogame',
  Notebook = 'Notebook',
  AirConditioner = 'Ar condicionado',
  Tv = 'Televisão',
  Monitor = 'Monitor',
  Refrigerator = 'Geladeira',
  WashingMachine = 'Máquina de lavar',
  Fan = 'Ventilador',
  AirFryer = 'Fritadeira elétrica',
  Microwave = 'Microondas',
  Stove = 'Fogão',
}

export type DealProductSuggestion = Pick<
  Product,
  | 'id'
  | 'title'
  | 'image'
  | 'categoryId'
  | 'currentMinPrice'
  | 'totalStoresCount'
> & { rangePercentageMessage: Pick<RangePercentageMessage, 'status'> };

type ProductVariantValue = {
  productId: string;
  name: string;
  productTitle: string;
  image?: Image;
  currentVariant?: boolean;
  genericVariant?: boolean;
};

export type TopProduct = DealProductSuggestion & Pick<Product, 'nominalModel'>;

export type TopProductsByCategory = {
  category: ProductCategory;
  image?: Image;
  products: TopProduct[];
};

export type TopProductsByModel = {
  model: string;
  image?: Image;
  products: TopProduct[];
};

export type ProductVariant = {
  type: string;
  values: ProductVariantValue[];
};
