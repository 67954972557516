import { useModal } from '@pelando/components';
import { useCallback, useState } from 'react';
import { Bells, RocketLaunch, Check } from '@pelando/fontawesome/icons';
import { LockScrollGlobally } from '@/presentation/styles/global';
import { AlertsComponentNames } from '@/domain/events/analytics';
import Translation from '@/presentation/components/Translation';
import { AlertType } from '../../../../../../domain/entities/Alert';
import {
  CheckIcon,
  Container,
  CreateButton,
  Listbox,
  ModalHeader,
  ModalIconTitle,
  Option,
  OptionButton,
  OptionIcon,
  Subtitle,
  Title,
  modalStyle,
} from './styles';
import { useTranslation } from '../../../../../hooks/useTranslation';
import { Category } from '../../../../../../domain/entities/Category';

type AlertTypePickerProps = {
  initialType: AlertType;
  onConfirm: (type: AlertType) => void;
};

export function AlertTypePicker({
  initialType,
  onConfirm,
}: AlertTypePickerProps) {
  const { t } = useTranslation('alerts');
  const [alertType, updateAlertType] = useState(initialType);

  const isInstant = alertType === AlertType.Instant;
  const isSmart = alertType === AlertType.Smart;

  return (
    <Container>
      <ModalHeader>
        <ModalIconTitle icon={Bells} />
        <Title>{t('notification-picker-modal-title')}</Title>
        <Subtitle>
          <Translation
            translation={t('notification-picker-modal-subtitle')}
            hasTag
          >
            Você ainda pode <mark>acessar todas as promoções</mark> relacionadas
            aos seus alertas na aba de promoções
          </Translation>
        </Subtitle>
      </ModalHeader>
      <Listbox role="listbox">
        <Option role="option" aria-selected={isSmart}>
          <OptionButton
            type="button"
            onClick={() => updateAlertType(AlertType.Smart)}
          >
            <OptionIcon icon={RocketLaunch} />

            <Translation
              translation={t('notification-picker-modal-is-smart')}
              hasTag
            >
              Receber só as <mark>melhores</mark>
            </Translation>
            {isSmart ? <CheckIcon icon={Check} /> : undefined}
          </OptionButton>
        </Option>
        <Option role="option" aria-selected={isInstant}>
          <OptionButton
            type="button"
            onClick={() => updateAlertType(AlertType.Instant)}
          >
            <OptionIcon icon={Bells} />

            <Translation
              translation={t('notification-picker-modal-is-instant')}
              hasTag
            >
              Receber <mark>todas</mark> as notificações
            </Translation>
            {isInstant ? <CheckIcon icon={Check} /> : undefined}
          </OptionButton>
        </Option>
      </Listbox>
      <CreateButton responsive onClick={() => onConfirm(alertType)}>
        {t('notification-picker-modal-confirm-button')}
      </CreateButton>
      <LockScrollGlobally lock />
    </Container>
  );
}

export type UseAlertTypePickerParams = {
  onConfirm: (
    term: string,
    type: AlertType,
    category?: Category,
    componentName?: AlertsComponentNames
  ) => void;
};

export function useAlertTypePicker({ onConfirm }: UseAlertTypePickerParams) {
  const { showModal, closeModal } = useModal();

  const handleConfirm: UseAlertTypePickerParams['onConfirm'] = useCallback(
    (term, type, source, category) => {
      onConfirm(term, type, source, category);
      closeModal();
    },
    [closeModal, onConfirm]
  );

  const showAlertTypePicker = useCallback(
    (
      initialType: AlertType,
      term: string,
      category?: Category,
      componentName?: AlertsComponentNames
    ) => {
      showModal(
        <AlertTypePicker
          onConfirm={(type) =>
            handleConfirm(term, type, category, componentName)
          }
          initialType={initialType}
        />,
        {
          style: modalStyle,
        }
      );
    },
    [showModal, handleConfirm]
  );

  return showAlertTypePicker;
}
