import { Category } from './Category';
import { FeedDeal } from './Offer';

export const enum AlertType {
  Instant = 'INSTANT',
  Smart = 'SMART',
}

export type Alert = {
  id: string;
  rawKeyword: string;
  createdAt: Date;
  type: AlertType;
  manyNotifications: boolean;
  category?: Category;
};

export type FeedAlertItem = {
  id: string;
  offer: FeedDeal;
  keyword: string;
};

export type AlertSuggestion = {
  term: string;
  type: AlertType;
  manyNotifications: boolean;
  category?: Category;
};

export type InitialAlertSuggestion = AlertSuggestion & { id?: string };

export enum CreateAlertSource {
  MANUAL = 'manual',
  AUTOCOMPLETE = 'autocomplete',
  SUGGESTION = 'suggestion',
  DEAL = 'deal',
}
