import { Category } from '../../../../domain/entities/Category';
import { createAlert, getAlerts } from '../endpoints';
import { CreateAlertVariables } from '../types/CreateAlert';

type CreateAlertParams = CreateAlertVariables['alert'] & {
  category?: Category;
  componentSourceName?: string;
  origin?: string;
};

export const createAndAddAlert = async ({
  rawKeyword,
  type,
  category,
  componentSourceName,
  origin,
}: CreateAlertParams) => {
  const response = await createAlert.requestAsPromise(
    {
      alert: {
        rawKeyword,
        type,
      },
    },
    {
      componentSourceName,
      clientInfo: {
        extra: {
          origin,
        },
      },
    }
  );

  const { data } = response;

  if (!data) return response;

  const alerts = [...(getAlerts.cache$.value?.alerts || [])];

  const alert = {
    ...data,
    category,
  };

  alerts.push(alert);
  getAlerts.cache$.next({ alerts, loading: false });

  return response;
};
