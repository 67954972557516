export const GET_INITIAL_ALERTS_SUGGESTIONS_QUERY = `
query GetInitialAlertsSuggestionsQuery {
  me {
    initialAlertKeywordSuggestions {
      term
      type
      manyNotifications
      category {
        id
        title
        slug
      }
    }
  }
}`;
