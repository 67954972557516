import { switchMap, timer, share } from 'rxjs';
import { getAlertsCount as getAlertsCountEndpoint } from '../endpoints';

const SECONDS_TO_MS = 1000;
const ALERTS_COUNT_REFRESH_INTERVAL = 30 * SECONDS_TO_MS;

export const getAlertsCount$ = timer(0, ALERTS_COUNT_REFRESH_INTERVAL).pipe(
  switchMap(() => getAlertsCountEndpoint.request()),
  share()
);

export const clearAlertsCountCache = () =>
  getAlertsCountEndpoint.cache$.next({
    count: 0,
  });
