import { DEAL_FEED_FRAGMENT } from '../../offers/queries/shared';

export const ALERTS_FEED_QUERY = `
  query AlertsFeedQuery(
    $limit: Int
    $after: String
    $filters: OfferFilterOptions
  ) {
    me {
      alertOffers(limit: $limit, after: $after, filters: $filters) {
        edges {
          id
          offer {
            ...DealFeedFragment
          }
          keyword
        }

        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${DEAL_FEED_FRAGMENT}
`;
