export const GET_ALERTS_QUERY = `
  query GetAlertsQuery {
    me {
      alerts {
        id
        rawKeyword
        createdAt
        type
        manyNotifications
        category {
          id
          title
          slug
        }
      }
    }
  }
`;
