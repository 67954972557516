import { OfferId } from './Offer';

export enum VoteKind {
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
}

export type Vote = {
  id: OfferId;
  voted: boolean;
  kind: VoteKind;
};
