import { OFFER_FRAGMENT } from '../../fragments/offer';

const VOTE_MUTATION = `
  mutation VoteMutation($id: ID!, $voteKind: VoteKind!, $options: OfferOptions) {
    me {
      vote(id: $id, kind: $voteKind, options: $options) {
        ...OfferFragment
      }
    }
  }
  ${OFFER_FRAGMENT}
`;

export default VOTE_MUTATION;
