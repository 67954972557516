const USER_OFFER_VOTES_QUERY = `
  query UserOfferVotesQuery($offerIds: [ID!]!) {
    me {
      offerVotes(offerIds: $offerIds) {
        id
        voted
        kind
      }
    }
  }
`;

export default USER_OFFER_VOTES_QUERY;
